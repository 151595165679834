import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {DefaultPlayer as Video} from 'react-html5video';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, FreeMode } from "swiper/modules";
import ReactModal from 'react-modal';

import 'swiper/swiper-bundle.css';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import "swiper/css/thumbs";

import 'react-html5video/dist/styles.css';
import '../../styles/howtobuy.css';


const HowBuy = () => {

    const { t } = useTranslation();

    const VideoSrc = () => {
        return "static/video/how-to-buy.mp4";
    }

    const images = [
        './static/images/how-buy/1.png',
        './static/images/how-buy/2.png',
        './static/images/how-buy/3.png',
        './static/images/how-buy/4.png',
        './static/images/how-buy/5.png',
      ];

    const [popupOpen, setPopupOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  
    const openPopup = (index) => {
        window.history.pushState(null, "", window.location.pathname + '#'+index);

        // close modal on 'back'
        window.onpopstate = () => {
            window.onpopstate = () => {}
            setPopupOpen(false)
        }

        setSelectedImageIndex(index);
        setPopupOpen(true);
    };
  
    const closePopup = () => {
        setPopupOpen(false);
    };

    return (
        <main className='how-buy-page'> 

        <img className="how_light" src="./static/images/bg-header.png" alt="" />
        <img className="how_budda" src="./static/images/budda-mb-hight.png" alt="" />
        {/* <img className="how_light-two" src="./static/images/Ellipse 77.png" alt="" />
        <img className="how_light-three" src="./static/images/Ellipse 77.png" alt="" />
        <img className="how_light-four" src="./static/images/Ellipse 77.png" alt="" /> */}
            <h1 className="how-to-buy-title">{t('HowTitle')}</h1>
            <div className="videoblock">
                {/* <img src="./static/images/Stars15.png" alt=""/>123 */}
                <Video
                    poster="static/images/how-to-buy.jpg"
                    src={VideoSrc()}
                >
                    <source src={VideoSrc()}/>
                </Video>
                <p>{t('videoBlock')}</p>
                <div className="videoblock_someinfo">{t('videoInfo')}</div>
            </div>
            <section className='steps' >
                
                        <div className="numerous">
                            <div className="hollow" onClick={() => openPopup(0)}>
                                <h2>1</h2>
                                <img src="./static/images/how-buy/1.png" alt="" />
                            </div>
                            <p>
                                {t('steps')} <a target="_blank" rel="noreferrer" href="https://metamask.io/">MetaMask.</a>
                            </p>
                        </div>

                        <div className="numerous">
                            <div className="hollow img_contain" onClick={() => openPopup(1)}>
                                <h2>2</h2>
                                <img src="./static/images/how-buy/2.png" alt="" />
                            </div>
                            <p>
                                {t('steps2')}
                            </p>
                        </div>

                        <div className="numerous">
                            <div className="hollow" onClick={() => openPopup(2)}>
                                <h2>3</h2>
                                <img src="./static/images/how-buy/3.png" alt="" />
                            </div>
                            <p>
                                {t('steps3')}
                            </p>
                        </div>

                        <div className="numerous">
                            <div className="hollow" onClick={() => openPopup(3)}>
                                <h2>4</h2>
                                <img src="./static/images/how-buy/4.png" alt="" />
                            </div>
                            <p>
                                {t('steps4')}
                            </p>
                        </div>

                        <div className="numerous">
                            <div className="hollow" onClick={() => openPopup(4)}>
                                <h2>5</h2>
                                <img src="./static/images/how-buy/5.png" alt=""/>
                            </div>
                            <p>
                                {t('steps5')}
                            </p>
                        </div>
            </section>

        <ReactModal 
        isOpen={popupOpen} 
        onRequestClose={closePopup}

        ariaHideApp={false}
        contentLabel="Selected Option"

        style={{
            overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: '99',
            },
            content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '90%',
            maxHeight: '90%',
            paddingBottom: '10px',
            },
        }}
        >
            <Swiper
				    pagination={{ clickable: true }}
					navigation={true} modules={[FreeMode, Navigation, Pagination]}
                spaceBetween={10}
                slidesPerView={1}
                initialSlide={selectedImageIndex}
                onSlideChange={(swiper) => setSelectedImageIndex(swiper.activeIndex)}
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <img src={image} alt={`img ${index + 1}`} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </ReactModal>
        </main>
    );
};

export default HowBuy;