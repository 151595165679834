import React from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ethers } from 'ethers';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
//import WalletConnect from "@walletconnect/client";
import WalletLink from "walletlink";

const providerOptions = {
	walletconnect: {
		package: WalletConnectProvider,
		options: {
		  infuraId: "d18e5cb4fb744f3b9502f6205db03827"
		}
	  },
	  walletlink: {
		package: WalletLink, 
		options: {
		  appName: "Buddha NFT Minter", 
		  infuraId: "d18e5cb4fb744f3b9502f6205db03827", 
		  rpc: "", 
		  chainId: 1, 
		  appLogoUrl: null, 
		  darkMode: false 
		}
	  },
};

const web3Modal = new Web3Modal({
  network: "mainnet",
  cacheProvider: false,
  providerOptions 
});


const Home = () => {
    const [walletAddress, setWalletAddress] = useState("...");
    const [provider, setProvider] = useState();

    const ADDRESS = "0x69a184bd63bac8de82c662ec803fae9cefa17083";
        
    const ABI = [
    {
        "inputs":[],
        "name":"NFTPrice",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            }
        ],
        "stateMutability":"view",
        "type":"function"
    },
    {
        "inputs":[],
        "name":"NFTPriceFoundation",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            }
        ],
        "stateMutability":"view",
        "type":"function"
    },

    {
        "inputs":[
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            },
            {
                "internalType":"address",
                "name":"",
                "type":"address"
            }
        ],
        "name":"Whitelist",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            }
        ],
        "stateMutability":"view",
        "type":"function"
    },
    {"inputs":[],"name":"mint","outputs":[],"stateMutability":"payable","type":"function"},

    {
        "anonymous":false,
        "inputs":[
            {"indexed":true,"internalType":"address","name":"from","type":"address"},
            {"indexed":true,"internalType":"address","name":"to","type":"address"},
            {"indexed":true,"internalType":"uint256","name":"tokenId","type":"uint256"}
        ],
        "name":"Transfer",
        "type":"event"
    }
    ]

    // Create a provider to interact with a smart contract
    async function connectWallet() {
        if (isMobileDevice() && walletAddress === '...') {
            ConnectMobile();
            return true;
        }

        console.log('v1.3')
        console.log('trying to connect wallet...');

        const provid = await web3Modal.connect();

        try {
            const web3 = new ethers.providers.Web3Provider(provid)
            setProvider(web3);

            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            setWalletAddress(accounts[0]);
            console.log('done!');

            return true;
        } catch (error) {
            console.log('Error connecting...');

            return false;
        }
    }
    
    async function mint() {
        // alert('in development');
        // return true;

        if (isMobileDevice() && walletAddress === '...') {
            ConnectMobile();
            return true;
        }

        if (walletAddress === '...' || !provider) {
            console.log('no wallet')
            await connectWallet();
        }

        console.log('get provider')

        if (provider !== undefined) {
            //const provider = new ethers.providers.Web3Provider(provider)
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner()
            const account = signer.getAddress();
            const contract = new ethers.Contract(ADDRESS, ABI, signer);

            //1) Запросить в контракте состоит ли человек в whitelist foundation
            //2) Если состоит, то одна цена, если не состоит, то другая
            //3) Если состоит в любом другом whitelist, то цена 0

            // 0 - Crowdfinding
            // 1 - Foundation 
            // 2 - GiveAway
            // 3 - Partners
            // 4 - Team
            const wlTypes = [0,1,2,3,4]
            const iFoundation = 1;
            let wlID = -1;
            let NFTPrice = 0;

            for (const wlType of wlTypes) {
                const resultWhitelist = await contract.Whitelist(wlType, account);
                const isGetAddress = ethers.BigNumber.from(resultWhitelist).toNumber();

                console.log('wlType', wlType);
                console.log('resultWhitelist', resultWhitelist);
                console.log('isGetAddress', isGetAddress);

                if (isGetAddress>0) {
                    wlID = wlType;
                    break;
                }
            }

            console.log('wlID', wlID);

            if (wlID>=0) {
                if (wlID === iFoundation) {
                    NFTPrice = await contract.NFTPriceFoundation();
                }
            }
            else {
                NFTPrice = await contract.NFTPrice();
            }

            console.log('NFTPrice', NFTPrice);
            console.log('NFT Price:', ethers.utils.formatEther(NFTPrice), 'ETH');

            //запросить цену foundation contract.NFTPriceFoundation
            //запросить обычную цену contracnt.NFTPric

            try {
                const tx = await contract.mint({
                    value: NFTPrice,
                    gasLimit: 6000000
                });
                console.log('Transaction:', tx);
            } catch (error) {
                console.error('Error minting token:', error);
            }

            contract.events.Transfer({
                filter: {from: account}, 
                fromBlock: 0
            }, function(error, event){ console.log(); })
            .on('data', function(mint){
                alert("Done!");
            })
            .on('error', function(error, receipt) {
                // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
                console.log(receipt);
            });
        }
    }

  const { t } = useTranslation();

    return (
        <>
        <div className='wallet-block container'>
            <div className="sensey">
              <div className="logo_sensey_container">
                <h1>SENSAY</h1>
                <img className="sensay_star" src="static/images/sensay_star.svg" alt="" />
              </div>
                <div className="mint-wrap _pc">
                    <button className="mint-top" onClick={mint}>MINT</button>
                    <button className="wallet-top" onClick={connectWallet}>
                        <span 
                        dangerouslySetInnerHTML={{__html: t('wallet')}}
                        />
                        <svg xmlns="http://www.w3.org/2000/svg" width="39" height="34" viewBox="0 0 39 34" fill="none">
                            <path d="M0 4.25C0 1.9125 1.755 0 3.9 0H33.15C33.6672 0 34.1632 0.223883 34.5289 0.622398C34.8946 1.02091 35.1 1.56141 35.1 2.125V4.25H3.9V6.375H37.05C37.5672 6.375 38.0632 6.59888 38.4289 6.9974C38.7946 7.39591 39 7.93641 39 8.5V29.75C39 30.8772 38.5891 31.9582 37.8577 32.7552C37.1263 33.5522 36.1343 34 35.1 34H3.9C2.86566 34 1.87368 33.5522 1.14228 32.7552C0.410892 31.9582 0 30.8772 0 29.75V4.25ZM32.175 23.375C32.9508 23.375 33.6947 23.0392 34.2433 22.4414C34.7918 21.8436 35.1 21.0329 35.1 20.1875C35.1 19.3421 34.7918 18.5314 34.2433 17.9336C33.6947 17.3358 32.9508 17 32.175 17C31.3992 17 30.6553 17.3358 30.1067 17.9336C29.5582 18.5314 29.25 19.3421 29.25 20.1875C29.25 21.0329 29.5582 21.8436 30.1067 22.4414C30.6553 23.0392 31.3992 23.375 32.175 23.375Z" fill="url(#paint0_linear_427_270)"/>
                            <defs>
                            <linearGradient id="paint0_linear_427_270" x1="-87.5" y1="45" x2="92.3627" y2="76.6316" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#6AE6C4"/>
                            <stop offset="1" stopColor="#8C84F6"/>
                            </linearGradient>
                            </defs>
                        </svg>
                    </button>
                </div>
                <div className="btn-wrapp-test _mobile">
                    <button className="mint-test" onClick={mint}>MINT</button>
                    <button className={t('wallet') === 'Connect <br /> wallet' ? 'wrallet-test yes': 'wrallet-test no'} onClick={connectWallet}>
                           <span
                               dangerouslySetInnerHTML={{__html: t('wallet')}}
                           />
                        <svg  width="39" height="34" viewBox="0 0 39 34" fill="none">
                            <path d="M0 4.25C0 1.9125 1.755 0 3.9 0H33.15C33.6672 0 34.1632 0.223883 34.5289 0.622398C34.8946 1.02091 35.1 1.56141 35.1 2.125V4.25H3.9V6.375H37.05C37.5672 6.375 38.0632 6.59888 38.4289 6.9974C38.7946 7.39591 39 7.93641 39 8.5V29.75C39 30.8772 38.5891 31.9582 37.8577 32.7552C37.1263 33.5522 36.1343 34 35.1 34H3.9C2.86566 34 1.87368 33.5522 1.14228 32.7552C0.410892 31.9582 0 30.8772 0 29.75V4.25ZM32.175 23.375C32.9508 23.375 33.6947 23.0392 34.2433 22.4414C34.7918 21.8436 35.1 21.0329 35.1 20.1875C35.1 19.3421 34.7918 18.5314 34.2433 17.9336C33.6947 17.3358 32.9508 17 32.175 17C31.3992 17 30.6553 17.3358 30.1067 17.9336C29.5582 18.5314 29.25 19.3421 29.25 20.1875C29.25 21.0329 29.5582 21.8436 30.1067 22.4414C30.6553 23.0392 31.3992 23.375 32.175 23.375Z" fill="url(#paint0_linear_427_2701)"/>
                            <defs>
                                <linearGradient id="paint0_linear_427_2701" x1="-87.5" y1="45" x2="92.3627" y2="76.6316" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#6AE6C4"/>
                                    <stop offset="1" stopColor="#8C84F6"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
                <div className="mb-star-bg" background="red">
                    <img src="static/images/full-mb-budda.png" alt="" />
                </div>
                </>
    );

};

export default Home;


//if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){ 

  function isMobileDevice() {
    return 'ontouchstart' in window || 'onmsgesturechange' in window;
  }
  
  
  async function connect(onConnected) {
    if (isMobileDevice()){
      ConnectMobile();
    } else if (!window.ethereum) {
      alert("Get MetaMask!");
      return;
    }
  
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
  
    onConnected(accounts[0]);
  }
  
    function ConnectMobile() {
        console.log('go mobile')
        const dappUrl = "lumeta.io";
        const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
        if(/iPhone|iPad|iPod/i.test(navigator.userAgent)){ 
            window.location.replace(metamaskAppDeepLink);
        } else {
            alert("Please do: \n 1) Open Metamask browser\n 2) Enter https://"+dappUrl)
            window.location.replace(metamaskAppDeepLink);
        }
    }



async function changeChain() {
  if (window.ethereum) {
      try {
        await window.ethereum.request({method: "eth_requestAccounts"})
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x1' }], 
        });
      } catch (error) {
        if (error.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: '0x1',
                  rpcUrl: 'https://eth-mainnet.nodereal.io/v1/1659dfb40aa24bbb8153a677b98064d7',
                },
              ],
            });
          } catch (addError) {
            console.error(addError);
          }
        }
        console.error(error);
      }
    }
}

async function checkIfWalletIsConnected(onConnected) {
    if (window.ethereum) {
        const accounts = await window.ethereum.request({
        method: "eth_accounts",
        });

        if (accounts.length > 0) {
        const account = accounts[0];
        onConnected(account);
        return;
        }

        if (isMobileDevice()) {
        await connect(onConnected);
        }
    }
}

function openMetaMaskUrl(url) {
    const a = document.createElement("a");
    a.href = url;
    a.target = "_self";
    document.body.appendChild(a);
    a.click();
    a.remove();
}